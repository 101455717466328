<template>
	<div class="filtriLista">
		<FiltriLista @cambiaFiltro="filtraTodos" />
	</div>
	<div v-if="err" class="lista">
		<h2>Si è verificato un errore, riprovare</h2>
	</div>
	<div v-if="todos.lenght < 1" class="lista">
		<h2>Nessun elemento nella lista</h2>
	</div>
	<div id="todoContainer">
		<Todo
			v-for="(todo, index) in todos"
			:titolo="todo.titolo"
			:descrizione="todo.descrizione"
			:stato="todo.stato"
			:id="todo.id"
			@rimosso="rimuoviElemento($event)"
			@cambioStato="cambiaStatoInLista($event, index)"
		/>
	</div>
</template>

<script>
	import FiltriLista from './FiltriLista.vue';
	import Todo from './Todo.vue';
	export default {
		components: { FiltriLista, Todo },
		data() {
			return {
				todos: [],
				todosOriginali: [],
				err: false,
				vista: 'tutti',
			};
		},
		methods: {
			filtraTodos(filtro) {
				//	console.log('filro per: ' + filtro);
				switch (filtro) {
					case 'tutti':
						//console.log('mnasdfasdfsd');
						this.todos = this.todosOriginali;
						this.vista = filtro;
						break;
					case 'daFare':
						this.todos = this.todosOriginali.filter(
							(todo) => todo.stato == 0
						);
						this.vista = filtro;
						//console.log('dafd');
						break;
					case 'fatti':
						this.todos = this.todosOriginali.filter(
							(todo) => todo.stato == 1
						);
						this.vista = filtro;
						break;
					default:
						console.error('No filter match');
						break;
				}
			},
			rimuoviElemento(idTodo) {
				this.todos = this.todos.filter((todo) => todo.id != idTodo);
			},
			fetchAllTodos(response) {
				console.log(response);
				if (response.status != 200) {
					this.err = true;
				} else {
					this.todosOriginali = response.data;
					this.filtraTodos(this.vista);
				}
			},
			cambiaStatoInLista(stato, index) {
				console.log('NuovoStato: ' + this.todos[index].stato);
				this.todosOriginali[index].stato = stato ? 1 : 0;
				this.todos[index].stato = stato ? 1 : 0;
				this.filtraTodos(this.vista);
			},
		},
		mounted() {
			//console.warn('MOUNTED FetchL istaTOdos');
			fetch('https://riccardov2.sg-host.com/api/todos')
				.then((res) => res.json())
				.then((res) => this.fetchAllTodos(res))
				.catch((err) => (this.err = true));
		},
	};
</script>

<style>
	.lista {
		background-color: white;
		border-radius: 10px;
		padding: 2%;
		text-align: center;
		width: 90%;
		max-width: 800px;
		margin: 0 auto;
	}
	#todoContainer {
		margin: 0 auto;
		max-width: 850px;
		max-height: 78vh;
		overflow-y: auto;
	}
</style>
