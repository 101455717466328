<template>
	<div>
		<span
			@click="$router.push({ name: 'add' })"
			class="nav_ico material-icons"
		>
			add
		</span>
		<div>
			<h1>Project To-Do</h1>
			<ListaTodos />
		</div>
	</div>
</template>

<script>
	import ListaTodos from '../components/ListaTodos.vue';
	export default {
		name: 'Home',
		components: { ListaTodos },
	};
</script>
<style scoped>
	.nav_ico {
		color: white;
		cursor: pointer;
		font-size: 5.5vh;
		position: absolute;
		top: 2vh;
		right: 3vw;
		background-color: rgb(52, 73, 100);
		border-radius: 100px;
	}
</style>
