<template>
	<!--<transition
		appear
		name="animation"
		enter-active-class="animate__animated animate__bounceInLeft delay"
		leave-active-class="animate__animated animate__backOutLeft"
	>
	</transition>-->
	<div>
		<span
			@click="$router.push({ name: 'home' })"
			class="nav_ico material-icons"
		>
			arrow_back
		</span>
		<h1 style="margin-top: 10vh">Aggiungi To-Do</h1>
		<FormTodo
			v-if="!isLoading"
			msgButton="Aggiungi Nuovo To-Do"
			titolo=""
			desc=""
			@submitted="inviaDati($event)"
		/>
		<div v-if="isLoading">Caricando...</div>
	</div>
</template>

<script>
	import FormTodo from '../components/FormTodo.vue';
	export default {
		components: { FormTodo },
		name: 'Aggiungi',
		data() {
			return {
				isLoading: false,
				err: false,
			};
		},
		methods: {
			//manca gestione errori API
			inviaDati(data) {
				this.isLoading = true;
				let payload = [];
				payload = {
					titolo: data.titolo,
					descrizione: data.descrizione,
				};
				console.log(payload);
				fetch('https://riccardov2.sg-host.com/api/todos', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				})
					.then((payload) => payload.json())
					.then((response) => {
						console.log(response);
						setTimeout(
							() => this.$router.push({ name: 'Home' }),
							800
						);
					});
			},
		},
	};
</script>
<style scoped>
	.nav_ico {
		cursor: pointer;
		font-size: 5.5vh;
		position: absolute;
		top: 2vh;
		left: 3vw;
		color: white;
		background-color: rgb(52, 73, 100);
		border-radius: 100px;
	}
</style>
