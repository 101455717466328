<template>
	<form action="#" @submit.prevent="fireSubmit" @click="debugga">
		<label>Titolo</label>
		<input type="text" v-model="titolo" />
		<label>Descrizione</label>
		<input type="text" class="desc" v-model="desc" />
		<button type="submit">{{ msgButton }}</button>
	</form>
</template>

<script>
	export default {
		props: ['msgButton', 'titolo', 'desc'],
		methods: {
			fireSubmit() {
				this.$emit('submitted', {
					descrizione: this.desc,
					titolo: this.titolo,
				});
			},
			debugga() {
				console.log(this.$route.params.id);
			},
		},
	};
</script>

<style>
	form {
		background-color: transparent;
		max-width: 650px;
		width: 90vw;
		margin: 0 auto;
		margin-top: 40px;
		border-radius: 20px;
		padding: 3%;
	}
	input {
		padding: 15px;
		font-size: 1.9em;
		display: block;
		width: 90%;
		background-color: #fff;
		border-radius: 50px;
		border: 1px solid #aaa;
		margin: 0 auto;
		margin-bottom: 30px;
		margin-top: 10px;
	}
	.desc {
		min-height: 90px;
	}
	button {
		padding: 20px;
		margin: 0 auto;
		border-radius: 50px;
		background-color: transparent;
		border: 2px solid royalblue;
		color: royalblue;
		font-weight: bolder;
		font-size: 1.3em;
	}
	button:hover {
		background-color: royalblue;
		color: white;
		cursor: pointer;
	}
	label {
		border: 1px solid f;
		font-size: 1.3em;
		font-weight: bold;
		width: 100%;
	}
</style>
