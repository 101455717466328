<template>
	<div class="container" :class="{ fatto: stato == 1 }">
		<div class="titolo" @click="toggleDesc">
			<h2>{{ titolo }}</h2>
		</div>
		<div class="azioni">
			<!--
			<span class="material-icons" @click="modificaTodo()"> create </span>-->
			<span class="material-icons" @click="eliminaTodo()"> delete </span>
			<span
				class="material-icons"
				:class="{ fatto: stato == 1 }"
				style="font-weight: 700"
				@click="cambiaStato()"
			>
				done
			</span>
		</div>
		<transition
			enter-active-class="animazione animate__animated animate__slideInDown  "
		>
			<div v-if="showDesc" class="descrizione">
				{{ descrizione }}
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showDesc: false,
				err: false,
			};
		},
		props: ['titolo', 'descrizione', 'stato', 'id'],
		methods: {
			toggleDesc() {
				this.showDesc = !this.showDesc;
			},
			modificaTodo() {
				const id = this.id;
				this.$router.push({ name: 'edit', params: { id } });
				//console.log(this.id);
			},
			cambiaStato() {
				const nuovoStato = !this.stato ? 1 : 0;
				const endPoint =
					'https://riccardov2.sg-host.com/api/todos/' + this.id;
				const payload = { stato: nuovoStato };
				//	console.log('payload: ' + payload);
				fetch(endPoint, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				})
					.then((data) => data.json())
					.then((response) => console.log(response))
					.catch((err) => (this.err = true));
				this.$emit('cambioStato', nuovoStato);
			},
			eliminaTodo() {
				this.$emit('rimosso', this.id);
				const endPoint =
					'https://riccardov2.sg-host.com/api/todos/delete/' +
					this.id;
				fetch(endPoint, {
					method: 'post',
				})
					.then((data) => data.json())
					.then((response) => console.log(response))
					.catch((err) => (this.err = true));
			},
		},
	};
</script>

<style scoped>
	.container {
		background-color: white;
		border-radius: 10px;
		padding-bottom: 30px;
		padding-top: 30px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		width: 95%;
		margin: 0 auto;
		margin-top: 20px;
		border-left: 5px solid rgb(253, 15, 162);
	}
	.titolo {
		width: 68%;
		padding-left: 2%;
		text-align: left;
		cursor: pointer;
		color: #2c3e50;
	}
	.azioni {
		width: 25%;

		font-size: 3em;
	}
	span {
		font-size: 45px !important;
		margin-left: 10px;
		cursor: pointer;
		color: #a2a4a7;
	}
	span:hover {
		color: #1f3346;
	}
	.fatto {
		border-color: chartreuse;
		color: chartreuse;
	}
	.fatto:hover {
		color: rgb(114, 182, 45);
	}

	.descrizione {
		font-size: 18px;
		margin-top: 20px;
		padding-top: 30px;
		border-top: 1px solid #cfcfcf;
		width: 90%;
		color: #2c3e50;
	}
</style>
