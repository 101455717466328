<template>
	<div>
		<a
			href="#"
			v-for="filter in filters"
			:filter="filter.val"
			:class="filter.val == selectedVal.val ? 'selected' : ''"
			@click="cambiaFiltro"
		>
			{{ filter.text }}
		</a>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				filters: [
					{ val: 'tutti', text: 'Tutti' },
					{ val: 'daFare', text: 'Da Fare' },
					{ val: 'fatti', text: 'Fatti' },
				],
				selectedVal: { val: 'tutti', text: 'Tutti' },
			};
		},
		methods: {
			cambiaFiltro(evento) {
				let res;
				//console.log(evento);
				//console.log('trovato = ' + evento.srcElement.attributes.filter.value);
				res = this.filters.filter((dato) => {
					return (
						evento.srcElement.attributes.filter.value == dato.val
					);
				});
				//	console.log(res[0].val);
				this.selectedVal = res[0];

				this.$emit('cambiaFiltro', this.selectedVal.val);
			},
		},
	};
</script>

<style scoped>
	.selected {
		color: #1f7ce7;
	}
	div {
		margin: 20px;
	}
</style>
