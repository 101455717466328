<template>
	<router-view v-slot="{ Component }">
		<transition
			appear
			name="animation"
			enter-active-class="animazione animate__animated animate__slideInLeft "
		>
			<!-- 
			leave-active-class=" animate__animated animate__zoomOutRight"
		-->
			<component :is="Component" />
		</transition>
	</router-view>
	<!--<transition
		appear
		name="animation"
		enter-active-class="animate__animated animate__zoomInLeft delay "
		leave-active-class="animate__animated animate__zoomOutRight"
	>
		<router-view />
	</transition> -->
</template>
<script>
	export default {};
</script>
<style>
	body {
		background-color: #f3f3f3;
		overflow-y: hidden;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	a {
		font-weight: bold;
		color: #2c3e50;
		margin: 10px;
	}

	h1 {
		font-size: 2.5em;
		margin-top: 5vh;
	}

	.animazione {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	::-webkit-scrollbar-thumb {
		background: linear-gradient(52deg, #9b9b9b 10%, #18425a7e 64%);
		border-radius: 15px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: linear-gradient(13deg, #61b0d8 14%, #707070 64%);
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 15px;
		box-shadow: inset 7px 10px 12px #f0f0f0;
	}
</style>
