<template>
	<!--<transition
		appear
		name="animation"
		enter-active-class="animate__animated animate__bounceInLeft delay"
		leave-active-class="animate__animated animate__backOutLeft"
	>
	</transition>-->
	<div>
		<h1>Modifica To-Do</h1>
		<h3 v-if="isLoading">Caricando...</h3>
		<FormTodo
			v-if="!isLoading"
			msgButton="Aggiungi Nuovo To-Do"
			@submitted="API_put_modiciche($event)"
		/>
	</div>
</template>

<script>
	import FormTodo from '../components/FormTodo.vue';
	export default {
		components: { FormTodo },
		name: 'Modifica',
		data() {
			return {
				isLoading: true,
				err: false,
			};
		},
		methods: {
			API_put_modifiche(data) {
				let payload = [];
				payload['titolo'] = data.titolo;
				payload['descrizione'] = data.descrizione;
				console.log(payload);
				$endPoint = fetch('https://riccardov2.sg-host.com/api/todos', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				})
					.then((data) => response.json())
					.then((response) => console.log(response))
					.then(() => (this.isLoading = false));
			},
		},
	};
</script>
